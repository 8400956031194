'use client';

import React from 'react';

// import { useTelegram } from '@/context/useTelegramContext'
// import { useRouter } from 'next/navigation'
import { useSocketSubscription } from '@/hooks/useSocketSubscription';
import { useAppStore } from '@/store/useAppStore';

const MainWrapper = ({
    children,
}: Readonly<{
    children: React.ReactNode;
}>) => {
    const { getUserIP } = useAppStore();
    // const router = useRouter()
    // const { platform, isReady } = useTelegram()

    // React.useEffect(() => {
    // 	if (isReady && (platform === 'tdesktop' || platform === 'macos')) {
    // 		router.push('/not-found')
    // 	}
    // }, [isReady])

    React.useEffect(() => {
        getUserIP();
    }, []);

    useSocketSubscription();

    return <>{children}</>;
};

export default MainWrapper;
