'use client'

import React from 'react'
import { SOCKET_EVENT } from '@/lib/types/SocketEvent.type'
import { socket } from '@/api/socket'
import { useCurrentContestStore } from '@/store/useCurrentContestStore'

export const useSocketSubscription = () => {
	const { updateParticipantsList } = useCurrentContestStore()

	React.useEffect(() => {
		socket.on(SOCKET_EVENT.CONNECT, () => {
			console.info('[OK] Connected to https://contest.lalau.ru WS server')
		})

		socket.on(SOCKET_EVENT.UPDATE_LIKES, updateParticipantsList)

		socket.on(SOCKET_EVENT.BOOST_SURVEY, updateParticipantsList)

		return () => {
			socket.off(SOCKET_EVENT.UPDATE_LIKES, updateParticipantsList)
			socket.off(SOCKET_EVENT.BOOST_SURVEY, updateParticipantsList)
		}
	}, [])
}
