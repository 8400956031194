'use client'

import React from 'react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

export const queryClient = new QueryClient()

const _QueryClientProvider = ({
	children,
}: Readonly<{
	children: React.ReactNode
}>) => {
	return (
		<QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
	)
}

export default _QueryClientProvider
