import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { ICurrentContestStore } from '@/lib/types/store/CurrentContestStore.type'
import { IParticipant } from '@/lib/types/services/Competiton.type'
import { getCookie } from 'cookies-next'

export const useCurrentContestStore = create<ICurrentContestStore>()(
	devtools((set, get) => ({
		currentContest: null,
		userLikeId: -1,
		setUserLikeId: userID => {
			set({ userLikeId: userID })
		},
		setCurrentContest: contest => {
			const likeUserId = contest?.participants_list.find(
				x => x.like_status == true
			)

			set({
				currentContest: contest,
				userLikeId: likeUserId?.user_id || -1,
			})
		},
		updateParticipantsList: (payload: IParticipant[]) => {
			const currentContest = get().currentContest

			if (currentContest) {
				set({
					currentContest: {
						...currentContest,
						participants_list: [...payload],
					},
				})
			}
		},
	}))
)
