'use client'

import React from 'react'
import { useMobileCropperStore } from '@/store/useMobileCropperStore'
import MobileCropper from '../MobileCropper/MobileCropper'

const _MobileCropperProvider = ({
	children,
}: Readonly<{
	children: React.ReactNode
}>) => {
	const { isOpen } = useMobileCropperStore()
	return (
		<>
			{isOpen && <MobileCropper />} {children}
		</>
	)
}

export default _MobileCropperProvider
