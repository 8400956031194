import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { IMobileCropperStore } from '@/lib/types/store/MobileCropperStore.type'

export const useMobileCropperStore = create<IMobileCropperStore>()(
	devtools(set => ({
		isOpen: false,
		image: null,
		uploadId: null,
		openCropper: (image: string, uploadId: string) => {
			set({
				isOpen: true,
				image,
				uploadId,
			})
		},
		closeCropper: () => {
			set({
				isOpen: false,
				image: null,
				uploadId: null,
			})
		},
	}))
)
