import React from 'react'
import { CropperRef, Cropper } from 'react-mobile-cropper'

import './mobileCropper.scss'
import 'react-mobile-cropper/dist/style.css'

import Button from '../UI/Button/Button'
import { useMobileCropperStore } from '@/store/useMobileCropperStore'
import { dataURItoBlob } from '@/lib/helpers/dataUrlToBlob.helper'
import EventBus from '@/lib/utils/EventBus'
import { EventBusTypes } from '@/lib/types/EventBus.type'

const MobileCropper = () => {
	const cropperRef = React.useRef<CropperRef>()
	const { image, closeCropper, uploadId } = useMobileCropperStore()

	const onCrop = () => {
		const cropper = cropperRef.current

		if (cropper) {
			const canvas = cropper.getCanvas()?.toDataURL()
			let blob = dataURItoBlob(canvas as string)
			const file = new File([blob as Blob], 'test.png', { type: 'image/png' })

			EventBus.$dispatch(EventBusTypes.CropFile, { file, uploadId })

			closeCropper()
		}
	}

	return (
		<div className={'mini-cropper'}>
			<Cropper
				ref={cropperRef}
				className={'cropper'}
				src={image}
				stencilProps={{
					aspectRatio: 3 / 4,
					minAspectRatio: 3 / 4,
					maxAspectRatio: 3 / 4,
				}}
			/>

			<div className='mini-cropper__controls'>
				<Button text={'Обрезать'} onClick={onCrop} />
				<Button text={'Отмена'} className='btn_cancel' onClick={closeCropper} />
			</div>
		</div>
	)
}

export default MobileCropper
