import { EventBusTypes } from '../types/EventBus.type'

const EventBus = {
	$on(eventType: EventBusTypes, callback: (event: CustomEvent) => void) {
		document.addEventListener(eventType, callback as (e: Event) => void)
	},

	$dispatch(eventType: EventBusTypes, data: any) {
		const event = new CustomEvent(eventType, { detail: data })
		document.dispatchEvent(event)
	},

	$remove(eventType: EventBusTypes, callback: (event: CustomEvent) => void) {
		document.removeEventListener(eventType, callback as (e: Event) => void)
	},
}

export default EventBus
